<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) If you have 1 mole of an ideal gas in a syringe like container (shown below), what would
        happen to the volume of the gas when the following changes are made (assuming all other
        things remain constant)?
      </p>

      <p class="mb-5 pl-14">
        <v-img src="/img/assignments/syringe.png" max-width="319px" alt="image of syringe" />
      </p>

      <v-simple-table class="mb-4">
        <thead>
          <tr>
            <th style="font-size: 15px"></th>
            <th style="font-size: 15px">Decreases</th>
            <th style="font-size: 15px">No change</th>
            <th style="font-size: 15px">Increases</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Temperature increases</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="decreases" value="decreases" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="noChange" value="not change" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="increases" value="increases" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>Pressure decreases</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="decreases" value="decreases" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="noChange" value="not change" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="increases" value="increases" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>1 mol of gas is added</td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="decreases" value="decreases" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="noChange" value="not change" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="increases" value="increases" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.textResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemCPP1210S4_Q3',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        textResponse: null,
      },
    };
  },
};
</script>
